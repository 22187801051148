import { transparentize, lighten } from "polished"

const _pcMaxWidth     = 900;
const _pcMinWidth     = 900;
const _tabletMinWidth = 600;
const _mobileMinWidth = 320;
const _baseTextColor  = '#444';
const _linkTextColor  = '#36F';
const _normalFonts    = '"Open Sans", "Segoe UI", Arial, "BIZ UDゴシック", "BIZ UDGothic", "メイリオ", Meiryo, -apple-system, BlinkMacSystemFont, sans-serif';

// styled-components デフォルトテーマ
const DefaultTheme = {
  // サイズ
  spacingUnit: '4px',
  contentWidth: {
    min: `${_mobileMinWidth}px`,
    max: `${_pcMaxWidth}px`,
  },
  bannerWidth: `${_pcMaxWidth - 180}px`,
  height: {
    headerInMobile: '60px',
  },
  // レスポンシブ
  breakpoint: {
    pcMin:     `${_pcMinWidth}px`,
    tabletMax: `${_pcMinWidth - 1}px`,
    tabletMin: `${_tabletMinWidth}px`,
    mobileMax: `${_tabletMinWidth - 1}px`,
  },
  // 色定義
  color: {
    main:     '#08A6A1',   // メインカラー（テーマカラー）
    accent:   '#0825A6',   // アクセントカラー（差し色）
    krpc:     '#0063ac',   // Kibo-RPCのテーマカラー（固定）
    deadline: '#E46',      // 締め切り（応募期限）色
    // 文字
    text: {
      primary:   _baseTextColor,
      secondary: transparentize(0.3, _baseTextColor),
      tertiary:  transparentize(0.6, _baseTextColor),
      disabled:  transparentize(0.5, '#777'),
    },
    // ハイパーリンク
    link: {
      normal: _linkTextColor,
      hover:  lighten(0.2, _linkTextColor),
      active: '#F33',
    },
    // 枠線
    border: transparentize(0.88, _baseTextColor),
    // 背景
    background: {
      page: '#FFF',
    },
  },
  // フォント
  font: {
    family: {
      normal:    _normalFonts,
      monospace: '"Roboto Mono", Menlo, Consolas, Monaco, "BIZ UDゴシック", "BIZ UDGothic", monospace, sans- serif',
      icons:     '"Material Icons"',
      special:   `"Saira Extra Condensed", ${_normalFonts}`,
    },
    size: {
      base: '16px',
      h1: '2.0rem',
      h2: '1.75rem',
      h3: '1.53rem',
      h4: '1.34rem',
      h5: '1.17rem',
      h6: '1.05rem',
      body1: '1.0rem',
      body2: '0.875rem',
      caption: '0.75rem',
    },
  },
  // 表示順
  zIndex: {
    toTopButton: 100,
    mobileMenu: 200,
    modal: 300,
  },
};

export default DefaultTheme;
